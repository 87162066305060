export const constantsTypes = {
    VIEW_MODE : "VIEW_MODE",
    TRAILER_MODE:"TRAILER_MODE",
    ADD_TO_CART:'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    LOGIN_VIEW:"LOGIN_VIEW",
    MOVIE_DETAILS_VIEW:"MOVIE_DETAILS_VIEW",
    MOBILE_FILTER:'MOBILE_FILTER',
    NOW_SHOWING_MOVIE:'NOW_SHOWING_MOVIE',
    COMING_SOON_MOVIE:'COMING_SOON_MOVIE',
    GRAND_TOTAL:'GRAND_TOTAL',
}